import { useEffect, useRef, useState } from "react";
import Baslik from "../comps/Baslik"
import DalgaAlt from "../comps/DalgaAlt"
import Footer from "../comps/Footer"

import { useCookies } from 'react-cookie';
import SeritHakkimizda from "../comps/SeritHakkimizda";

import titizAgroLogo_1 from '../assets/images/titizAgroLogo_1.png';

function Hakkimizda() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtHakkimizda, setTxtHakkimizda] = useState(null);
    const [txtMetin1, setTxtMetin1] = useState(null);
    const [txtMetin2, setTxtMetin2] = useState(null);
    const [txtMetin3, setTxtMetin3] = useState(null);
    const [txtMetin4, setTxtMetin4] = useState(null);
    const [txtMetin5, setTxtMetin5] = useState(null);
    const [txtMetin6, setTxtMetin6] = useState(null);
    const [txtMetin7, setTxtMetin7] = useState(null);
    const [txtMetin8, setTxtMetin8] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtHakkimizda('about us');
            setTxtMetin1('Our companies, which have operated as the Antalya Tarim Group since 1981, have been a leading force in Turkish agriculture and beyond. We have reorganized our operations under a new company, the Titiz Agro Group.');
            setTxtMetin2('I have personally witnessed all the stages of our group`s growth since our first company was established in 1981. ');
            setTxtMetin3('At first glance, i can say a lot has changed since then, and that growth came gradually. ');
            setTxtMetin4('On a deeper note, regardless of all the visible changes, what has remained the same through the years are our belief in making a difference and our commitment to agriculture. Today, with over 1.000 employees, 85 of which are agricultural engineers, we are still moving the agriculture sector forward by investing in our people and in innovation. ');
            setTxtMetin6('Titiz Agro Group is a family bussiness at its core. The values we embrace are reflected in the way we define "the family bussiness" which extends to include all our employees, the growers, the companies we team up with and our business partners all around the world. ');
            setTxtMetin7('As Titiz Agro Group, we are looking forward to doing business with you as well, with the hope of creating positive change together in the agriculture of the future.');
            setTxtMetin8('Warmest regards,');

        } else {
            setTxtHakkimizda('hakkımızda');
            setTxtMetin1('1981 yılından bugüne Antalya Tarım A.Ş. adı altında faaliyet gösteren şirketlerimiz, Türk tarımında öncü firmaların başında gelmektedir. Türk tarımına daha iyi ve yeni hizmetler katmak için, tüm faaliyetlerimizi Titiz Agrogrup adı altında yapılandırdık.');
            setTxtMetin2('1981 yılında ilk şirketimizin kuruluşundan itibaren, grup şirketlerimizin büyümesine şahsen şahit oldum. İlk bakışta o günden bugüne birçok şeyin değiştiğini ve büyümenin aşama aşama gerçekleştiğini söyleyebilirim. ');
            setTxtMetin3('Tüm görsel değişimlere rağmen yıllar boyunca aynı kalan, tarıma bağlılığımız, sorumluluğumuz ve farklılık yaratma inancımızdır. ');
            setTxtMetin4('Bugün 85`i ziraat mühendisi olmak üzere 1000 çalışanımızla, ziraat sektörünü yeniliğe ve insanlara yatırım yaparak ileri götürmekteyiz. ');
            setTxtMetin5('Titiz Agrogrup özünde bir aile şiirketidir. Sahiplendiğimiz değerler "aile şirketi" olarak tanımladığımız olguyu yansıtmaktadır. Bu olgu tüm çalışanlarımızı, üreticilerimizi, tüm dünyada birlikte çalıştığımız şirket ve iş ortaklarımızı kapsamaktadır. ');
            setTxtMetin6('Titiz Agrogrup olarak, tarımda olumlu değişimleri yaratmak için gelecekte de birlikte çalışmak umuduyla. ');
            setTxtMetin7('');
            setTxtMetin8('Saygılarımla');
        }
        
    },[cookies.titizAgroWebLanguage])

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);


    return(
        <>
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                maxWidth: '100vw',
                minHeight: '100vh',
                overflowX: "hidden",
                overflowY: "hidden",
                backgroundColor: 'white',
            }}
        >
        <Baslik />
        <div
            style={{
                width: '100vw',
                height: '105px',
            }}
        >
        </div>
        <div
            className="fontGenel"
            style={{
                
            }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12"
                    >
                        <SeritHakkimizda />
                    </div>
                    <div
                        className="col-12"
                    >

                        <div
                            className='container-fluid mt-5 mb-5'
                            style={{
                                textAlign: 'justify',
                                width: '90vw',
                            }}
                        >
                            <div
                                className='row'
                            >
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{
                                            textAlign: 'justify'
                                        }}
                                    >
                                        {txtMetin1}
                                    </p>
                                </div>
                                <div
                                    className="col-12 m-2 yatayDikeyOrtala"
                                    style={{

                                    }}
                                >

                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin2}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin3}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin4}
                                    </p>
                                </div>
                                <div
                                    className="col-12 m-2 yatayDikeyOrtala"
                                    style={{

                                    }}
                                >
                                    
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin5}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin6}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin7}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        {txtMetin8}
                                    </p>
                                </div>
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{

                                        }}
                                    >
                                        Dr. K. Savaş Titiz
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}
        >
            <Footer />
        </div>
        
        <DalgaAlt />

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </div>
        </>
    )
} export default Hakkimizda