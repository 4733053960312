
import './SeritSirketlerimiz.css';

import { useCookies } from 'react-cookie';

import { motion } from "framer-motion"

import { useEffect, useState } from 'react';

function SeritSirketlerimiz() {

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtSirketlerimiz, setTxtSirketlerimiz] = useState(null);


    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtSirketlerimiz('companies');

        } else {
            setTxtSirketlerimiz('şirketlerimiz');
        }
        
    },[cookies.titizAgroWebLanguage])



    return(
        <>
        <div
            style={{
                // overflow: 'hidden',
            }}
        >

            <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                className="container-fluid"
                style={{
                    position: 'absolute',
                    top: '100px',
                    left: 0,
                    margin: 0,
                    padding: 0,
                    width: '100vw',
                    height: '300px',
                    overflow: 'hidden',
                    // backgroundColor: 'blue',
                }}
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12 yatayDikeyOrtala"
                    >
                        <motion.div
                            initial={{ scale: 1, opacity: 0, x: -50, transform: 'rotate(0deg)' }}
                            whileInView={{ opacity: 1, x: 0, transform: 'rotate(20deg)' }}
                            // whileHover={{ scale: 1.2, opacity: '0.5' }}
                            style={{
                                // position: 'relative',
                                // top: -200,
                                // left: -100,
                                position: 'absolute',
                                top: -100,
                                left: -100,
                                width: '20vw',
                                height: '800px',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                // transform: 'rotate(20deg)',
                                zIndex: 5,
                            }}
                        >
                        </motion.div>
                        <motion.div
                            initial={{ x: 0, scale: 1 }}
                            whileHover={{ x: 10, scale: 1.1 }}
                            style={{
                                position: 'absolute',
                                top: '135px',
                                left: '8vw',

                                color: 'black',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                zIndex: 6,
                            }}
                        >
                            <span>{txtSirketlerimiz}</span>
                        </motion.div>
                        <motion.div
                            className='d-none d-lg-flex'
                            initial={{ scale: 1, opacity: 0, x: -50, transform: 'rotate(0deg)' }}
                            whileInView={{ opacity: 1, x: 0, transform: 'rotate(20deg)' }}
                            // whileHover={{ scale: 1.2, opacity: '0.5' }}
                            style={{
                                // position: 'relative',
                                // top: -200,
                                // left: -100,
                                position: 'absolute',
                                top: -150,
                                left: -100,
                                width: '40vw',
                                height: '800px',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                // transform: 'rotate(20deg)',
                                zIndex: 3,
                            }}
                        >
                        </motion.div>
                        <motion.div
                            className='d-none d-lg-block'
                            initial={{ x: 0, scale: 1 }}
                            whileHover={{ x: 10, scale: 1.1 }}
                            style={{
                                position: 'absolute',
                                top: '140px',
                                left: '22vw',

                                color: 'black',
                                fontSize: '25px',
                                // fontWeight: 'bold',
                                zIndex: 4,
                            }}
                        >
                            {/* <span>Domates</span>
                            <br/>
                            <span>Oval Kokteyl</span> */}
                        </motion.div>
                        <motion.div
                            initial={{ x: 0, scale: 1 }}
                            whileHover={{ x: 10, scale: 1.1 }}
                            style={{
                                position: 'absolute',
                                top: '0px',
                                left: '0vw',

                                color: 'black',
                                fontSize: '30px',
                                // fontWeight: 'bold',
                                zIndex: 1,
                            }}
                        >
                            {/* <img 
                                src={domatesSolen_400x1200}
                                style={{
                                    width: '100vw',
                                    height: '400px',
                                }}
                            /> */}
                        </motion.div>
                        <motion.div
                            initial={{ x: 0, scale: 1 }}
                            whileHover={{ x: 10, scale: 1.1 }}
                            className='arkaPlanGradient'
                            style={{
                                position: 'absolute',
                                top: '0px',
                                left: '0vw',

                                height: '400px',
                                width: '100vw',
                                // backgroundColor: 'linear-gradient(to right, rgba(142, 198, 65, 0), rgba(142, 198, 65, 1))',
                                // fontWeight: 'bold',
                                zIndex: 2,
                            }}
                        >

                        </motion.div>
                        <motion.div
                            initial={{ x: 0, scale: 1 }}
                            whileHover={{ x: -10, scale: 1.1 }}
                            style={{
                                position: 'absolute',
                                bottom: '20px',
                                right: '5vw',

                                // height: '400px',
                                // width: '100vw',
                                // backgroundColor: 'linear-gradient(to right, rgba(142, 198, 65, 0), rgba(142, 198, 65, 1))',
                                // fontWeight: 'bold',
                                zIndex: 3,
                            }}
                        >
                            
                        </motion.div>
                    </div>
                </div>
            </motion.div>

        </div>

        <div
            style={{
                height: 'calc(100px + 200px)',
            }}
        >

        </div>

        </>
    )
} export default SeritSirketlerimiz