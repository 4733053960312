
import './404.css';

import { useNavigate } from 'react-router-dom';


function DortYuzDort () {

    const navigate = useNavigate();

    setTimeout(() => {
        navigate('/');
    }, 3000);

    return (
        <>
        <div className='a'>
            <div className='a'>
                <div class="glitch" data-text="400">404</div>   
                <div className='glitch2' style={{fontSize: 45}} data-text="sayfa bulunamadı">
                    <span>sayfa bulunamadı</span>    
                </div>      
            </div>
        </div>
        </>
    )

}

export default DortYuzDort;