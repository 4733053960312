
import { Suspense } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { Routes ,Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import AnaSayfa from './pages/AnaSayfa';

import DortYuzDort from './pages/404';
import Hakkimizda from './pages/Hakkimizda';
import Iletisim from './pages/Iletisim';
import Sirketlerimiz from './pages/Sirketlerimiz';
import Duyurular from './pages/Duyurular';


function App() {
  return (
    <>
    <Router basename='/'>
      <Suspense
        fallback={
          <>
          <div
            style={{
              width: '100vw',
              height: '100vh',

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
            
            >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold'
,                }}
              >
                Yükleniyor...
              </span>
            </div>
          </div>
          </>
        }
      >
        <Routes>
          <Route path='/' element={<AnaSayfa />} />
          <Route path='/hakkimizda' element={<Hakkimizda />} />
          <Route path='/duyuru' element={<Duyurular />} />
          <Route path='/sirketlerimiz' element={<Sirketlerimiz />} />
          <Route path='/iletisim' element={<Iletisim />} />

          <Route path='*' element={<DortYuzDort />} />
        </Routes>

      </Suspense>

    </Router>
    </>
  );
}

export default App;
