import { useEffect, useRef, useState } from "react";
import Baslik from "../comps/Baslik"
import DalgaAlt from "../comps/DalgaAlt"
import Footer from "../comps/Footer"

import { useCookies } from 'react-cookie';
import SeritDuyurular from "../comps/SeritDuyurular";

import Duyuru1 from "../comps/Duyuru1";

function Duyurular() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtDuyurular, setTxtDuyurular] = useState(null);

    useEffect(() => {
        if(cookies.antalyaTarimLanguage === "eng"){
            setTxtDuyurular('Announcements');

        } else {
            setTxtDuyurular('Duyurular');
        }
        
    },[cookies.antalyaTarimLanguage])

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);


    return(
        <>
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                maxWidth: '100vw',
                minHeight: '100vh',
                overflowX: "hidden",
                overflowY: "hidden",
                backgroundColor: 'white',
            }}
        >
        <Baslik />
        <div
            style={{
                width: '100vw',
                height: '105px',
            }}
        >
        </div>
        <div
            className="fontGenel"
            style={{
                
            }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12"
                    >
                        <SeritDuyurular />
                    </div>
                    <div
                        className="col-12"
                    >

                        <div
                            className='container-fluid mt-5 mb-5'
                            style={{
                                textAlign: 'justify',
                                width: '90vw',
                            }}
                        >
                            {/* <div
                                className="row"
                            >
                                <div
                                    className="col-12 yatayDikeyOrtala"
                                >
                                    <span
                                        style={{
                                            fontSize: '30px',
                                        }}
                                    >
                                        {txtDuyurular}
                                    </span>
                                </div>
                            </div> */}

                            <div
                                className="row"
                            >
                                <div
                                    className="col-12 yatayDikeyOrtala"
                                >
                                    <div
                                        className="container-fluid"
                                    >
                                        <div
                                            className="row yatayDikeyOrtala"
                                        >
                                            <div
                                                className="col-md-12 col-lg-6 yatayDikeyOrtala"
                                            >
                                                <Duyuru1 />
                                            </div>
                                            <div
                                                className="col-md-12 col-lg-6 yatayDikeyOrtala"
                                            >
                                                {/* <Duyuru1 /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}
        >
            <Footer />
        </div>
        
        <DalgaAlt />

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </div>
        </>
    )
} export default Duyurular