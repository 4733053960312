import { useEffect, useRef, useState } from "react";
import Baslik from "../comps/Baslik"
import DalgaAlt from "../comps/DalgaAlt"
import Footer from "../comps/Footer"

import { useCookies } from 'react-cookie';
import SeritSirketlerimiz from "../comps/SeritSirketlerimiz";

import { AnimatePresence, motion } from 'framer-motion';


import titizAgroLogo_1 from '../assets/images/titizAgroLogo_1.png';
import { Card } from "react-bootstrap";

function Sirketlerimiz() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtSirketlerimiz, setTxtSirketlerimiz] = useState(null);
    const [txtTurkiye, setTxtTurkiye] = useState(null);
    const [txtMetin1, setTxtMetin1] = useState(null);
    const [txtMetin2, setTxtMetin2] = useState(null);
    const [txtMetin3, setTxtMetin3] = useState(null);
    const [txtMetin4, setTxtMetin4] = useState(null);
    const [txtMetin5, setTxtMetin5] = useState(null);
    const [txtMetin6, setTxtMetin6] = useState(null);
    const [txtMetin7, setTxtMetin7] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtTurkiye('Turkey');
            setTxtSirketlerimiz('group companies');
            setTxtMetin1('');
            setTxtMetin2('');
            setTxtMetin3('');
            setTxtMetin4('');
            setTxtMetin5('');
            setTxtMetin6('');
            setTxtMetin7('');

        } else {
            setTxtTurkiye('Türkiye');
            setTxtSirketlerimiz('şirketlerimiz');
            setTxtMetin1('');
            setTxtMetin2('');
            setTxtMetin3('');
            setTxtMetin4('');
            setTxtMetin5('');
            setTxtMetin6('');
            setTxtMetin7('');
        }
        
    },[cookies.titizAgroWebLanguage])

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);


    return(
        <>
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                maxWidth: '100vw',
                minHeight: '100vh',
                overflowX: "hidden",
                overflowY: "hidden",
                backgroundColor: 'white',
            }}
        >
        <Baslik />
        <div
            style={{
                width: '100vw',
                height: '105px',
            }}
        >
        </div>
        <div
            className="fontGenel"
            style={{
                
            }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12"
                    >
                        <SeritSirketlerimiz />
                    </div>
                    <div
                        className="col-12"
                    >

                        <div
                            className='container-fluid mt-5 mb-5'
                            style={{
                                textAlign: 'justify',
                                width: '90vw',
                            }}
                        >
                            <div
                                className='row'
                            >
                                <div
                                    className='col-12'
                                >
                                    <p
                                        style={{
                                            textAlign: 'justify'
                                        }}
                                    >
                                        <div
                                            className="container-fluid"
                                        >
                                            <div
                                                className="row"
                                            >
                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://antalyatarim.com.tr','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Antalya Tarım
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Antalya, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>
                                                
                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.agromar.com.tr','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Agromar
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Karacabey, Bursa, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('https://www.fleurantalya.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Fleurantalya
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Antalya, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            onClick={ () => window.open('http://www.medifinefoods.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Medi Fine Foods
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                İzmir, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            // onClick={ () => window.open('http://www.medifinefoods.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Antalya Fide
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Antalya, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            // onClick={ () => window.open('http://www.medifinefoods.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Çukurova Fide
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Mersin, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            // onClick={ () => window.open('http://www.medifinefoods.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Likya Fide & Çiçek
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Kumluca, Antalya, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                                <div
                                                    className="col-md-12 col-lg-4 p-1"
                                                >
                                                    <motion.div
                                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                                        whileInView={{ opacity: 1, y: 0 }}
                                                        whileHover={{ scale: 1.03 }}
                                                    >
                                                        <Card
                                                            // onClick={ () => window.open('http://www.medifinefoods.com','_blank') }
                                                        >
                                                            <Card.Body>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color:'rgba(140, 0, 0, 1)',
                                                                                    // fontWeight: 'bold',
                                                                                    fontSize: '20px',
                                                                                }}
                                                                            >
                                                                                Agromar Fide
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            className="col-12 yatayDikeyOrtala"
                                                                        >
                                                                            <span>
                                                                                Karacabey, Bursa, {txtTurkiye}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </motion.div>
                                                </div>

                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}
        >
            <Footer />
        </div>
        
        <DalgaAlt />

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </div>
        </>
    )
} export default Sirketlerimiz