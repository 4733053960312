import { Suspense, useEffect, useRef, useState } from "react";
import Baslik from "../comps/Baslik"
import DalgaAlt from "../comps/DalgaAlt"
import Footer from "../comps/Footer"

import { useCookies } from 'react-cookie';
import SeritIletisim from "../comps/SeritIletisim";

import { Accordion, Button, Card, FloatingLabel, Form, Table } from "react-bootstrap";

import { motion } from "framer-motion";

import axios from 'axios';

import Swal from 'sweetalert2';

import iconEtiket from '../assets/icons/iconEtiket.svg';
import iconMapPointer from '../assets/icons/iconMapPointer.svg';
import iconTelefon from '../assets/icons/iconTelefon.svg';
import iconFax from '../assets/icons/iconFax.svg';
import iconEmail from '../assets/icons/iconEmail.svg';
import iconMobilePhone from '../assets/icons/iconMobilePhone.svg';
import iconPerson from '../assets/icons/iconPerson.svg';
import iconEdit from '../assets/icons/iconEdit.svg';
import iconOkSag from '../assets/icons/iconOkSag.svg';

import SliderCaptch from 'rc-slider-captcha';

import captcha1 from '../assets/images/captcha1.webp';
import captcha2 from '../assets/images/captcha2.webp';
import LoadingIcerik from "../comps/LoadingIcerik";
import { Img } from "react-suspense-img";


function Iletisim() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtIletisim, setTxtIletisim] = useState(null);
    const [txtFirmaBilgileri, setTxtFirmaBilgileri] = useState(null);

    const [txtBizeYazin, setTxtBizeYazin] = useState(null);
    const [txtBizeYazinText1, setTxtBizeYazinText1] = useState(null);
    const [txtBizeYazinText2, setTxtBizeYazinText2] = useState(null);
    const [txtAdSoyad, setTxtAdSoyad] = useState(null);
    const [txtKonu, setTxtKonu] = useState(null);
    const [txtAciklama, setTxtAciklama] = useState(null);

    const [txtMerkez, setTxtMerkez] = useState(null);
    const [txtMerkez2, setTxtMerkez2] = useState(null);
    const [txtOfis, setTxtOfis] = useState(null);
    const [txtAdres, setTxtAdres] = useState(null);
    const [txtTelefon, setTxtTelefon] = useState(null);
    const [txtBolge, setTxtBolge] = useState(null);

    const [txtResimiDuzelt, setTxtResimiDuzelt] = useState(null);
    const [txtTamamlanincaBirak, setTxtTamamlanincaBirak] = useState(null);
    const [txtResimDuzenlenmedi, setTxtResimDuzenlenmedi] = useState(null);
    const [txtYukleniyor, setTxtYukleniyor] = useState(null);
    const [txtKontrolEdiliyor, setTxtKontrolEdiliyor] = useState(null);

    const [txtGonder, setTxtGonder] = useState(null);


    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtIletisim('Contact');
            setTxtFirmaBilgileri('Company Information');

            setTxtBizeYazin('Write To Us');
            setTxtBizeYazinText1('Please fill in all fields.');
            setTxtBizeYazinText2('Bu sizinle gerektiğinde iletişime geçebilmemiz için önemli.');
            setTxtAdSoyad('Name Surname');
            setTxtKonu('Subject');
            setTxtAciklama('Description');

            setTxtMerkez('Centeral');
            setTxtMerkez2('Head');
            setTxtOfis('Office');
            setTxtAdres('Address');
            setTxtTelefon('Telephone');
            setTxtBolge('Region');

            setTxtResimiDuzelt('Fix the picture for submit...');
            setTxtTamamlanincaBirak('Leave it when it`s done...');
            setTxtResimDuzenlenmedi('Picture didn`t fixed, error!');
            setTxtYukleniyor('Loading...');
            setTxtKontrolEdiliyor('Verifying');

            setTxtGonder('Submit');

        } else {
            setTxtIletisim('İletişim');
            setTxtFirmaBilgileri('Firma Bilgileri');

            setTxtBizeYazin('Bize Yazın');
            setTxtBizeYazinText1('Lütfen tüm alanları doldurunuz.');
            setTxtBizeYazinText2('This is important so that we can contact you when necessary.');
            setTxtAdSoyad('Ad Soyad');
            setTxtKonu('Konu');
            setTxtAciklama('Açıklama');
            
            setTxtMerkez('Merkez');
            setTxtMerkez2('Merkez');
            setTxtOfis('Ofis');
            setTxtAdres('Adres');
            setTxtTelefon('Telefon');
            setTxtBolge('Bölge');

            setTxtResimiDuzelt('Göndermek için resimi düzeltin...');
            setTxtTamamlanincaBirak('Tamamlanınca bırakın...');
            setTxtResimDuzenlenmedi('Resim düzelmedi, hata!');
            setTxtYukleniyor('Yükleniyor...');
            setTxtKontrolEdiliyor('Kontrol ediliyor...');

            setTxtGonder('Gönder');

        }
        
    },[cookies.titizAgroWebLanguage])

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);


    const handleTelefonLink1 = () => {
        window.location.href = 'tel:00902423212330';
    }
    const handleTelefonLink2 = () => {
        window.location.href = 'tel:00902423214687';
    }
    const handleEmailLink1 = () => {
        window.location.href = 'mailto:info@antalyatarim.com.tr';
    }
    const handleEmailLinkTitiz1 = () => {
        window.location.href = 'mailto:info@titizagrogrup.com.tr';
    }
    const handleAdres1 = () => {
        window.open('https://maps.app.goo.gl/L8f7ppGBgo5KhgMB6', '_blank');
    }
    const googleMapsLink = `
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12854.036820804666!2d30.700221016075865!3d36.882886177301124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385396551db85%3A0x45b45cbf12cedc8e!2sTitiz%20Agro%20Group!5e0!3m2!1str!2str!4v1706083277430!5m2!1str!2str" 
            width="100%"
            height="200" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
        </iframe>
    `;

    const handleTelefonAntalyaTarim1 = () => {
        window.location.href = 'tel:00902423212330';
    }
    const handleTelefonAgromar1 = () => {
        window.location.href = 'tel:00902246762427';
    }
    const handleTelefonMedi1 = () => {
        window.location.href = 'tel:00902328761167';
    }
    const handleTelefonLinkAdanaMersinBolge1 = () => {
        window.location.href = 'tel:00905339647042';
    }
    const handleTelefonLinkAlanyaGazipasaBolge1 = () => {
        window.location.href = 'tel:00905331938450';
    }
    const handleTelefonLinkFethiyeBolge1 = () => {
        window.location.href = 'tel:00905332825926';
    }
    const handleTelefonLinkIzmirBolge1 = () => {
        window.location.href = 'tel:00905336298753';
    }

    const refInputAdSoyad = useRef();
    const refInputTelefon = useRef();
    const refInputEmail = useRef();
    const refInputKonu = useRef();
    const refAciklama = useRef();

    const [inputAdSoyad, setInputAdSoyad] = useState(null);
    const [inputTelefon, setInputTelefon] = useState(null);
    const [inputEmail, setInputEmail] = useState(null);
    const [inputKonu, setInputKonu] = useState(null);
    const [inputAciklama, setInputAciklama] = useState(null);

    // ..CAPTCHA
    const [openCaptcha, setOpenCaptcha] = useState(true);

    const handleVerification = (isVerified) => {
        if (isVerified) {
          console.log('Captcha doğrulandı!');
          // Burada captcha doğrulandığında yapılması gereken işlemleri gerçekleştirin.
        } else {
          console.log('Captcha doğrulanmadı!');
          // Burada captcha doğrulanamadığında yapılması gereken işlemleri gerçekleştirin.
        }
    }
    // ..CAPTCHA

    const [formGonderButtonGoster, setFormGonderButtonGoster] = useState(false);

    function isValidEmail(email) {
        // Email adresi için geçerli bir regex deseni
        var pattern = /^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
        
        // Email adresini regex deseni ile karşılaştır
        return pattern.test(email);
    }

    const [formAdSoyadYaziKirmiziSiyah, setFormAdSoyadYaziKirmiziSiyah] = useState('black');
    const [formTelefonYaziKirmiziSiyah, setFormTelefonYaziKirmiziSiyah] = useState('black');
    const [formEmailYaziKirmiziSiyah, setFormEmailYaziKirmiziSiyah] = useState('black');
    const [formKonuYaziKirmiziSiyah, setFormKonuYaziKirmiziSiyah] = useState('black');
    const [formAciklamaYaziKirmiziSiyah, setFormAciklamaYaziKirmiziSiyah] = useState('black');

    const handleFormGonder = () => {

        if((inputAdSoyad === null) || (inputAdSoyad === undefined) || (inputAdSoyad === '') || (inputAdSoyad.length < 3)){
            Swal.fire({
                icon: 'warning',
                text: 'Adınızı ve soyadınızı doğru yazmanız gerekiyor!',
                showConfirmButton: false,
                timer: 2000,
            })
            setFormAdSoyadYaziKirmiziSiyah('red');
            setFormTelefonYaziKirmiziSiyah('black');
            setFormEmailYaziKirmiziSiyah('black');
            setFormKonuYaziKirmiziSiyah('black');
            setFormAciklamaYaziKirmiziSiyah('black');
            refInputAdSoyad.current.focus();
        }

        else if((inputTelefon === null) || (inputTelefon === undefined) || (inputTelefon === '') || (inputTelefon.length <= 9)){
            Swal.fire({
                icon: 'warning',
                text: 'Telefon numaranızı doğru yazmanız gerekiyor!',
                showConfirmButton: false,
                timer: 2000,
            })
            setFormAdSoyadYaziKirmiziSiyah('black');
            setFormTelefonYaziKirmiziSiyah('red');
            setFormEmailYaziKirmiziSiyah('black');
            setFormKonuYaziKirmiziSiyah('black');
            setFormAciklamaYaziKirmiziSiyah('black');
            refInputAdSoyad.current.focus();
        }

        else if(!isValidEmail(inputEmail)){
            Swal.fire({
                icon: 'warning',
                text: 'Geçerli bir email adresi yazmanız gerekiyor!',
                showConfirmButton: false,
                timer: 2000,
            })
            setFormAdSoyadYaziKirmiziSiyah('black');
            setFormTelefonYaziKirmiziSiyah('black');
            setFormEmailYaziKirmiziSiyah('red');
            setFormKonuYaziKirmiziSiyah('black');
            setFormAciklamaYaziKirmiziSiyah('black');
            refInputEmail.current.focus();
        }

        else if((inputKonu === null) || (inputKonu === undefined) || (inputKonu === '') || (inputKonu.length <= 4)){
            Swal.fire({
                icon: 'warning',
                text: 'İletişim konununzu en az 5 harf kullanarak belirtmeniz gerekiyor !',
                showConfirmButton: false,
                timer: 2000,
            })
            setFormAdSoyadYaziKirmiziSiyah('black');
            setFormTelefonYaziKirmiziSiyah('black');
            setFormEmailYaziKirmiziSiyah('black');
            setFormKonuYaziKirmiziSiyah('red');
            setFormAciklamaYaziKirmiziSiyah('black');
            refInputAdSoyad.current.focus();
        }

        else if((inputAciklama === null) || (inputAciklama === undefined) || (inputAciklama === '') || (inputAciklama.length <= 9)){
            Swal.fire({
                icon: 'warning',
                text: 'Mesajınızı en az 10 harf kullanarak yazmanız gerekiyor !',
                showConfirmButton: false,
                timer: 2000,
            })
            setFormAdSoyadYaziKirmiziSiyah('black');
            setFormTelefonYaziKirmiziSiyah('black');
            setFormEmailYaziKirmiziSiyah('black');
            setFormKonuYaziKirmiziSiyah('black');
            setFormAciklamaYaziKirmiziSiyah('red');
            refInputAdSoyad.current.focus();
        }

        else {
            setFormAdSoyadYaziKirmiziSiyah('black');
            setFormTelefonYaziKirmiziSiyah('black');
            setFormEmailYaziKirmiziSiyah('black');
            setFormKonuYaziKirmiziSiyah('black');
            setFormAciklamaYaziKirmiziSiyah('black');

            axios.post('', {
                adSoyad: inputAdSoyad,
                telefon: inputTelefon,
                email: inputEmail,
                konu: inputKonu,
                aciklama: inputAciklama,

            }).then(function(response){
                console.log(response);
            }).catch(function(error){
                console.log(error);
            }).finally(function(){
                console.log("axios işlem sonu");
            })

        }


    }

    return(
        <>
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                maxWidth: '100vw',
                minHeight: '100vh',
                overflowX: "hidden",
                overflowY: "hidden",
                backgroundColor: 'rgba(255,255,255, 1)',
            }}
        >
        <Baslik />
        <div
            style={{
                width: '100vw',
                height: '105px',
            }}
        >
        </div>
        <div
            className="fontGenel"
            style={{
                
            }}
        >
            <div
                className="container-fluid"
            >
                <div
                    className="row"
                >
                    <div
                        className="col-12"
                    >
                        <SeritIletisim />
                    </div>
                    <div
                        className="col-12"
                    >

                        <div
                            className='container-fluid mt-5 mb-5'
                            style={{
                                textAlign: 'justify',
                                width: '90vw',
                            }}
                        >
                            {/* <div
                                className="row"
                            >
                                <div
                                    className="col-12 yatayDikeyOrtala"
                                >
                                    <span
                                        style={{
                                            fontSize: '30px',
                                        }}
                                    >
                                        {txtIletisim}
                                    </span>
                                </div>
                            </div> */}

                            <div
                                className="row"
                            >
                                <div
                                    className="col-12 yatayDikeyOrtala"
                                >
                                    <div
                                        className="container-fluid"
                                    >
                                        <div
                                            className="row "
                                        >
                                            <div
                                                className="col-md-12 col-lg-6 my-3 yatayDikeyOrtala"
                                            >
                                                <motion.div
                                                    initial={{ opacity: 0, y: -10, scale: 1 }}
                                                    whileInView={{ opacity: 1, y: 0 }}
                                                    whileHover={{ scale: 1.03 }}

                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Card
                                                        style={{
                                                            width: '90%',
                                                        }}
                                                    >
                                                        <Card.Header>
                                                            <div
                                                                className="container-fluid"
                                                            >
                                                                <div
                                                                    className="row"
                                                                >
                                                                    <div
                                                                        className="col-1"
                                                                    >
                                                                        <Suspense
                                                                            fallback={<LoadingIcerik />}
                                                                        >
                                                                            <Img 
                                                                                src={iconEtiket}
                                                                                alt='icon etiket'
                                                                                style={{
                                                                                    width: '30px',
                                                                                }}
                                                                            />
                                                                        </Suspense>
                                                                    </div>
                                                                    <div
                                                                        className="col-11"
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize: '17px',
                                                                            }}
                                                                        >
                                                                            {txtFirmaBilgileri}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <div
                                                            // ..accordion ekle
                                                            >
                                                                <Accordion
                                                                    defaultActiveKey={['1']}
                                                                    // alwaysOpen
                                                                >
                                                                    <Accordion.Item
                                                                        eventKey="1"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>{txtMerkez} {txtOfis} (Antalya)</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleAdres1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <img 
                                                                                                    />
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Kızıltoprak Mahallesi Aspendos Bulvarı No: 37 Muratpaşa/Antalya
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLink1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconTelefon}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (242) 321 23 30                                                                                                </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLink2 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconFax}
                                                                                                            alt='icon fax'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>Fax</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90(242) 321 46 87 
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleEmailLinkTitiz1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconEmail}
                                                                                                            alt='icon email'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>E-Mail</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        info@titizagrogrup.com.tr 
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* ....... */}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
    {/* .. ANTALYA TARIM */}
                                                                    <Accordion.Item
                                                                        eventKey="2"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Antalya Tarım</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Kızıltoprak Mahallesi Aspendos Bulvarı No: 37 Muratpaşa/Antalya
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonAntalyaTarim1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt="icon telefon"
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (242) 321 23 30
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. AGROMAR */}
                                                                    <Accordion.Item
                                                                        eventKey="3"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Agromar</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Bandırma Yolu 1.Km. Karacabey / Bursa 
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonAgromar1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (224) 676 24 27
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. FLEURANTALYA */}
                                                                    <Accordion.Item
                                                                        eventKey="4"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Fleurantalya</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Antalya, Türkiye 
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonAgromar1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (224) 676 24 27
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            {/* <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. MEDI FINE FOODS */}
                                                                    <Accordion.Item
                                                                        eventKey="5"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Medi Fine Foods</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        İzmir, Türkiye 
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonMedi1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (232) 876 11 67
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. ANTALYA FİDE */}
                                                                    <Accordion.Item
                                                                        eventKey="6"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Antalya Fide</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Antalya, Türkiye
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLinkAdanaMersinBolge1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 533 964 70 42
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            {/* <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* ....... */}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. ÇUKUROVA FİDE */}
                                                                    <Accordion.Item
                                                                        eventKey="7"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Çukurova Fide</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Mersin, Türkiye
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLinkAdanaMersinBolge1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 533 964 70 42
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            {/* <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* ....... */}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. LİKYA FİDE ÇİÇEK */}
                                                                    <Accordion.Item
                                                                        eventKey="8"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Likya Fide & Çiçek</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Kumluca, Antalya, Türkiye {txtBolge}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLinkAlanyaGazipasaBolge1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (533) 193 84 50
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            {/* <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

    {/* .. AGROMAR FİDE */}
                                                                    <Accordion.Item
                                                                        eventKey="9"
                                                                    >
                                                                        <Accordion.Header>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        <span>Agromar Fide</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >

                                                                                        {/* ....... */}
                                                                                        <div
                                                                                            className="container-fluid"
                                                                                            style={{
                                                                                                fontSize: '13px',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="row"
                                                                                                // style={{
                                                                                                //     cursor: 'pointer'
                                                                                                // }}
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMapPointer}
                                                                                                            alt='icon mapPointer'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtAdres}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        Karacabey, Bursa, Türkiye
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div
                                                                                                className="row"
                                                                                                style={{
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onClick={ handleTelefonLinkFethiyeBolge1 }
                                                                                            >
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-2"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <Suspense
                                                                                                        fallback={<LoadingIcerik />}
                                                                                                    >
                                                                                                        <Img 
                                                                                                            src={iconMobilePhone}
                                                                                                            alt='icon telefon'
                                                                                                            style={{
                                                                                                                width: '20px',
                                                                                                                marginRight: '10px',
                                                                                                            }}
                                                                                                        />
                                                                                                    </Suspense>
                                                                                                    <span>{txtTelefon}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-none d-lg-flex col-lg-1 yatayDikeyOrtala"
                                                                                                >
                                                                                                    <span> : </span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-md-12 col-lg-9"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        justifyContent: 'left',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>
                                                                                                        +90 (533) 282 59 26
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            {/* <div
                                                                                                className="row"
                                                                                            >
                                                                                                <div
                                                                                                    className="col-12"
                                                                                                >
                                                                                                    <hr />
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        {/* ....... */}
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>

                                                                </Accordion>
                                                            </div>
                                                                <div
                                                                    className="container-fluid"
                                                                >
                                                                    <div
                                                                        className="row"
                                                                    >
                                                                        <div
                                                                            className="col-12"
                                                                        >
                                                                            <div 
                                                                                className='mt-2'
                                                                                dangerouslySetInnerHTML={{ __html: googleMapsLink }} 
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </Card.Body>
                                                    </Card>
                                                </motion.div>
                                            </div>

                                            {/* <div
                                                className="col-md-12 col-lg-6 mt-3"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <motion.div
                                                    initial={{ opacity: 0, y: -10, scale: 1 }}
                                                    whileInView={{ opacity: 1, y: 0 }}
                                                    whileHover={{ scale: 1.03 }}

                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Card
                                                        style={{
                                                            width: '90%',
                                                        }}
                                                    >
                                                        <Card.Header>
                                                            <div
                                                                className="container-fluid"
                                                            >
                                                                <div
                                                                    className="row"
                                                                >
                                                                    <div
                                                                        className="col-1"
                                                                    >
                                                                        <Suspense
                                                                            fallback={<LoadingIcerik />}
                                                                        >
                                                                            <Img 
                                                                                src={iconEdit}
                                                                                alt='icon edit'
                                                                                style={{
                                                                                    width: '30px',
                                                                                }}
                                                                            />
                                                                        </Suspense>
                                                                    </div>
                                                                    <div
                                                                        className="col-11"
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontSize: '17px',
                                                                            }}
                                                                        >
                                                                            {txtBizeYazin}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <div
                                                                className="container-fluid"
                                                                style={{
                                                                    fontSize: '13px',
                                                                }}
                                                            >
                                                                <div
                                                                    className="row"
                                                                >
                                                                    <div
                                                                        className="col-12 m-1"
                                                                    >
                                                                        <FloatingLabel
                                                                            className="mb-2-"
                                                                            label={
                                                                                <div
                                                                                    style={{
                                                                                        color: formAdSoyadYaziKirmiziSiyah,
                                                                                    }}
                                                                                >
                                                                                    <Suspense
                                                                                        fallback={<LoadingIcerik />}
                                                                                    >
                                                                                        <Img 
                                                                                            src={iconPerson}
                                                                                            alt='icon person'
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                        />
                                                                                    </Suspense>
                                                                                    {txtAdSoyad}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <Form.Control 
                                                                                ref={refInputAdSoyad}
                                                                                type="text"
                                                                                required
                                                                                placeholder="adSoyad"
                                                                                value={inputAdSoyad}
                                                                                onChange={ (event) => setInputAdSoyad(event.target.value) }
                                                                            />
                                                                        </FloatingLabel>
                                                                    </div>
                                                                    <div
                                                                        className="col-12 m-1"
                                                                    >
                                                                        <FloatingLabel
                                                                            className="mb-2-"
                                                                            label={
                                                                                <div
                                                                                    style={{
                                                                                        color: formTelefonYaziKirmiziSiyah,
                                                                                    }}
                                                                                >
                                                                                    <Suspense
                                                                                        fallback={<LoadingIcerik />}
                                                                                    >
                                                                                        <Img 
                                                                                            src={iconTelefon}
                                                                                            alt='icon telefon'
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                        />
                                                                                    </Suspense>
                                                                                    {txtTelefon}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <Form.Control 
                                                                                ref={refInputTelefon}
                                                                                type="number"
                                                                                required
                                                                                placeholder="telefon"
                                                                                value={inputTelefon}
                                                                                onChange={ (event) => setInputTelefon(event.target.value) }
                                                                            />
                                                                        </FloatingLabel>
                                                                    </div>
                                                                    <div
                                                                        className="col-12 m-1"
                                                                    >
                                                                        <FloatingLabel
                                                                            className="mb-2-"
                                                                            label={
                                                                                <div
                                                                                    style={{
                                                                                        color: formEmailYaziKirmiziSiyah,
                                                                                    }}
                                                                                >
                                                                                    <Suspense
                                                                                        fallback={<LoadingIcerik />}
                                                                                    >
                                                                                        <Img 
                                                                                            src={iconEmail}
                                                                                            alt='icon email'
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                        />
                                                                                    </Suspense>
                                                                                    E-Mail
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <Form.Control 
                                                                                ref={refInputEmail}
                                                                                type="email"
                                                                                required
                                                                                placeholder="email"
                                                                                value={inputEmail}
                                                                                onChange={ (event) => setInputEmail(event.target.value) }
                                                                            />
                                                                        </FloatingLabel>
                                                                    </div>
                                                                    <div
                                                                        className="col-12 m-1"
                                                                    >
                                                                        <FloatingLabel
                                                                            className="mb-2-"
                                                                            label={
                                                                                <div
                                                                                    style={{
                                                                                        color: formKonuYaziKirmiziSiyah,
                                                                                    }}
                                                                                >
                                                                                    <Suspense
                                                                                        fallback={<LoadingIcerik />}
                                                                                    >
                                                                                        <Img 
                                                                                            src={iconEtiket}
                                                                                            alt='icon etiket'
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                        />
                                                                                    </Suspense>
                                                                                    {txtKonu}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <Form.Control 
                                                                                ref={refInputKonu}
                                                                                type="text"
                                                                                required
                                                                                placeholder="konu"
                                                                                value={inputKonu}
                                                                                onChange={ (event) => setInputKonu(event.target.value) }
                                                                            />
                                                                        </FloatingLabel>
                                                                    </div>
                                                                    <div
                                                                        className="col-12 m-1"
                                                                    >
                                                                        <FloatingLabel
                                                                            className="mb-2-"
                                                                            label={
                                                                                <div
                                                                                    style={{
                                                                                        color: formAciklamaYaziKirmiziSiyah,
                                                                                    }}
                                                                                >
                                                                                    <Suspense
                                                                                        fallback={<LoadingIcerik />}
                                                                                    >
                                                                                        <Img 
                                                                                            src={iconEdit}
                                                                                            alt='icon edit'
                                                                                            style={{
                                                                                                width: '20px',
                                                                                                marginRight: '5px',
                                                                                            }}
                                                                                        />
                                                                                    </Suspense>
                                                                                    <img 
                                                                                    />
                                                                                    {txtAciklama}
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <Form.Control 
                                                                                ref={refAciklama}
                                                                                required
                                                                                placeholder="aciklama"
                                                                                value={inputAciklama}
                                                                                as='textarea'
                                                                                rows={3}
                                                                                style={{
                                                                                    height: '100px',
                                                                                }}
                                                                                onChange={ (event) => setInputAciklama(event.target.value) }
                                                                            />
                                                                        </FloatingLabel>
                                                                    </div>
                                                                    <div
                                                                        className="col-12 m-1 yatayDikeyOrtala"
                                                                    >
                                                                        <SliderCaptch
                                                                            style={{
                                                                                // width: '200px',
                                                                                borderRadius: '25px',
                                                                                // backgroundColor: 'yellow',
                                                                                // padding: '10px',
                                                                            }}  
                                                                            // bgSize={{ width: '250px'}}
                                                                            // puzzleSize={{ width: '50px'}}
                                                                            showRefreshIcon
                                                                            limitErrorCount={3}
                                                                            errorHoldDuration={1500}
                                                                            // placement="bottom" ??
                                                                            request={async () => {
                                                                                return {
                                                                                bgUrl: captcha1,
                                                                                puzzleUrl: captcha2,
                                                                                };
                                                                            }}
                                                                            onVerify={async (data) => {
                                                                                console.log(data);
                                                                                console.log(data.x);
                                                                                if(data.x && data.x > 185 && data.x < 195){
                                                                                    setFormGonderButtonGoster(true);
                                                                                    return Promise.resolve();
                                                                                } else {
                                                                                    setFormGonderButtonGoster(false);
                                                                                    return Promise.reject();
                                                                                }
                                                                                // verify data
                                                                                // return Promise.resolve();
                                                                                // return ver
                                                                            }}
                                                                            tipIcon={{
                                                                                default : <img src={iconOkSag} alt='icon okSag' />,
                                                                                // loading: 
                                                                                // success: 
                                                                                // error: 
                                                                                // refresh:  
                                                                            }}  
                                                                            tipText={{
                                                                                default: txtResimiDuzelt,
                                                                                loading: txtYukleniyor,
                                                                                moving: txtTamamlanincaBirak,
                                                                                verifying: 'verifying text',
                                                                                error: txtResimDuzenlenmedi,
                                                                            }}
                                                                            loadingBoxProps={{
                                                                                icon: <img src={iconEdit} alt='icon edit' />,
                                                                                text: txtYukleniyor,
                                                                            }}                                                                  
                                                                        />
                                                                    </div>
                                                                    {formGonderButtonGoster &&
                                                                    <>
                                                                    <div
                                                                        className="col-12 m-1 yatayDikeyOrtala"
                                                                    >
                                                                        <Button
                                                                            variant="outline-success"
                                                                            onClick={ () => handleFormGonder() }
                                                                        >
                                                                            <div
                                                                                className="container-fluid"
                                                                            >
                                                                                <div
                                                                                    className="row"
                                                                                >
                                                                                    <div
                                                                                        className="col-12"
                                                                                    >
                                                                                        {txtGonder}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Button>
                                                                    </div>
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </motion.div>
                                            </div> */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
            }}
        >
            <Footer />
        </div>
        
        <DalgaAlt />

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </div>
        </>
    )
} export default Iletisim