
import { Button } from 'react-bootstrap';
import '../App.css';


import titizAgroLogo_1 from '../assets/images/titizAgroLogo_1.png';
import titizLogo_1000x608 from '../assets/images/titizLogo_1000x608.webp';
import React, { Suspense, useEffect, useRef, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Img, resource } from 'react-suspense-img';

import { AnimatePresence, motion } from 'framer-motion';

import icon_englandusd from '../assets/icons/icon_englandusd.webp';
import icon_turkiye from '../assets/icons/icon_turkiye.webp';
import icon_hamburger from '../assets/icons/icon_hamburger.svg';
import LoadingIcerik from './LoadingIcerik';



function Baslik() {

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtAnaSayfa, setTxtAnaSayfa] = useState(null);
    const [txtHakkimizda, setTxtHakkimizda] = useState(null);
    const [txtDuyurular, setTtxDuyurular] = useState(null);
    const [txtSirketlerimiz, setTxtSirketlerimiz] = useState(null);
    const [txtUrunler, setTxtUrunler] = useState(null);
    const [txtIletisim, setTxtIletisim] = useState(null);
    const [txtDilSecenek, setTxtDilSecenek] = useState(null);


    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtAnaSayfa('Home');
            setTxtHakkimizda('About Us');
            setTtxDuyurular('Announcements');
            setTxtSirketlerimiz('Group Companies');
            setTxtUrunler('Products');
            setTxtIletisim('Contact Us')
            setTxtDilSecenek('Languages');
        } else {
            setTxtAnaSayfa('Ana Sayfa');
            setTxtHakkimizda('Hakkımızda');
            setTtxDuyurular('Duyurular');
            setTxtSirketlerimiz('Şirketlerimiz');
            setTxtUrunler('Ürünler');
            setTxtIletisim('İletişim');
            setTxtDilSecenek('Dil Seçimi')
        }
        
    },[cookies.titizAgroWebLanguage])

    const navigate = useNavigate();

    const navigate2AnaSayfa = () => {
        setModalMobileMenu(false);
        navigate('/');
    }
    const navigate2Hakkimizda = () => {
        navigate('/hakkimizda');
    }
    const navigate2Duyurular = () => {
        navigate('/duyuru');
    }
    const navigate2Sirketlerimiz = () => {
        navigate('/sirketlerimiz');
    }
    const navigate2Urunlerimiz = () => {
        navigate('/urunler');
    }
    const navigate2Iletisim = () => {
        navigate('/iletisim');
    }



    const refBaslik = useRef();

    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const baslik = refBaslik.current;

        const bolum1 = refBolum1.current;
        const bolum2 = refBolum2.current;


        gsap.to(baslik, 
            {
                x: 0,
                y: 0,
                scale: 1,
            }    
        )

        gsap.fromTo(baslik, 
            {
                y: 0,
                scale: 1,
            },
            {
                y: -20,
                scale: 0.8,
                scrollTrigger: {
                    trigger: bolum1,
                    start: '60% center',
                    end: 'bottom center',
                    scrub: true,
                    // markers: true,
                }
            }    
        )

    },[])

    const modalMobileMenuAcKapat = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: 50 },
    };

    const [modalMobileMenu, setModalMobileMenu] = useState(false);
    const handleModalMobileMenuKapat = () => {
        setModalMobileMenu(false);
    }

    return(
        <>
        <div
        ref={refBaslik}
            className="container-fluid"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '120px',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255 ,0.9)',
                borderRadius: '0px 0px 20px 20px',
                zIndex: 100,
                // backgroundColor: 'rgba(255, 158, 59, 0.5)',
                // backgroundColor: 'green',
            }}
        >
            <div
                className="row"
                style={{
                    width: '100%',
                    // backgroundColor: 'yellow',
                }}
            >
                {/* ..mobile */}
                <div
                    className="d-block d-lg-none col-8"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'right',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <Suspense
                        fallback={<LoadingIcerik />}
                    >
                    <Img  
                        // initial={{ opacity: 1, y: 0, scale: 1 }}
                        // // animate={{ opacity: 1, y: 0 }}
                        // whileHover={{ scale: 1.05 }}
                        src={titizLogo_1000x608}
                        alt='titiz logo'
                        style={{
                            margin: '20px',
                            width: '150px',
                            paddingTop: '20px',
                        }}
                        onClick={ () => navigate2AnaSayfa() }
                    />
                    </Suspense>

                </div>

                <div
                    className="d-none d-lg-block col-2"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                    }}
                >
                    <motion.div
                        initial={{ opacity: 1, y: -50, scale: 1 }}
                        animate={{ opacity: 1, y: 0 }}
                        whileHover={{ scale: 1.05 }}
                    >
                        <Suspense
                            fallback={<LoadingIcerik />}
                        >
                            <Img 
                                src={titizLogo_1000x608}
                                alt='titiz logo'
                                style={{
                                    margin: '20px',
                                    width: '150px',
                                    marginTop: '50px',
                                    marginLeft: '60px',
                                }}
                                onClick={ () => navigate2AnaSayfa() }
                            />
                        </Suspense>
                    </motion.div>
                    
                    
                </div>
                <div
                    className='d-none d-lg-block col-8'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',

                        // backgroundColor: 'red',
                    }}
                >
                    <div
                        className='container-fluid'
                        style={{
                            // backgroundColor: 'yellow',
                            width: '90vw',

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            paddingTop: '50px'
                        }}
                    >
                        <div
                            className='row'
                        >
                            <div
                                className='col-12'
                            >
                                <span
                                    className='fontGenel'
                                    style={{
                                        // fontWeight: 'bold',
                                        fontSize: '35px',
                                        marginLeft: '50px',
                                    }}
                                >
                                    Titiz Agro Grup A.Ş.
                                </span>
                            </div>
                            <div
                                className='col-12 fontGenel'
                                style={{
                                    fontSize: '15px',
                                }}
                            >
                                <div
                                    className='container-fluid'
                                >
                                    <div
                                        className='row'
                                    >
                                        <motion.div
                                            initial={{ opacity: 0, y: -50, scale: 1, fontWeight: 'normal', fontSize: '15px' }}
                                            animate={{ opacity: 1, y: 0  }}
                                            whileHover={{ scale: 1.05, fontWeight: 'bold', fontSize: '14px'  }}
                                            className='col-2 yatayDikeyOrtala'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ () => navigate2Hakkimizda() }
                                        >
                                            <span>{txtHakkimizda}</span>
                                        </motion.div>
                                        <div
                                            className='col-1 yatayDikeyOrtala'
                                        >
                                            <span>/</span>
                                        </div>
                                        <motion.div
                                            initial={{ opacity: 0, y: -50, scale: 1, fontWeight: 'normal', fontSize: '15px' }}
                                            animate={{ opacity: 1, y: 0  }}
                                            whileHover={{ scale: 1.05, fontWeight: 'bold', fontSize: '14px'  }}
                                            className='col-2 yatayDikeyOrtala'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ () => navigate2Duyurular() }
                                        >
                                            <span>{txtDuyurular}</span>
                                        </motion.div>
                                        <div
                                            className='col-1 yatayDikeyOrtala'
                                        >
                                            <span>/</span>
                                        </div>
                                        <motion.div
                                            initial={{ opacity: 0, y: -50, scale: 1, fontWeight: 'normal', fontSize: '15px' }}
                                            animate={{ opacity: 1, y: 0  }}
                                            whileHover={{ scale: 1.05, fontWeight: 'bold', fontSize: '14px'  }}
                                            className='col-2 yatayDikeyOrtala'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ () => navigate2Sirketlerimiz() }
                                        >
                                            <span>{txtSirketlerimiz}</span>
                                        </motion.div>
                                        <div
                                            className='col-1 yatayDikeyOrtala'
                                        >
                                            <span>/</span>
                                        </div>
                                        <motion.div
                                            initial={{ opacity: 0, y: -50, scale: 1, fontWeight: 'normal', fontSize: '15px' }}
                                            animate={{ opacity: 1, y: 0  }}
                                            whileHover={{ scale: 1.05, fontWeight: 'bold', fontSize: '14px'  }}
                                            className='col-2 yatayDikeyOrtala'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ () => navigate2Iletisim() }
                                        >
                                            <span>{txtIletisim}</span>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* .. mobile */}
                <div
                    className='d-block d-lg-none col-4'
                    style={{
                        marginTop: '50px',
                        // backgroundColor: 'yellow',
                    }}
                >
                    <div
                        className='container-fluid'
                    >
                        <div
                            className='row'
                        >
                            <div
                                className='col-12'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right',
                                    // backgroundColor: 'yellow'
                                }}
                            >
                                <Suspense
                                    fallback={<LoadingIcerik />}
                                >
                                    <Img 
                                        src={icon_hamburger}
                                        alt='icon hamburger'
                                        style={{
                                            width: '40px',
                                        }}
                                        // onClick={ () => setCookie('titizAgroWebLanguage', 'eng', {path: '/', maxAge: cookieMaxAge})}
                                        onClick={ () => setModalMobileMenu(!modalMobileMenu) }
                                    />
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='d-none d-lg-flex col-2'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',

                        // backgroundColor: 'red',
                    }}
                >
                    <div
                        className='container-fluid fontGenel'
                    >
                        <div
                            className='row mt-3'
                        >
                            <div
                                className='col-12 yatayDikeyOrtala'
                                style={{
                                    fontSize: '10px',
                                }}
                            >
                                <span>{txtDilSecenek}</span>
                            </div>
                            <div
                                className='col-6 mt-2'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'right',
                                }}
                            >
                                <Suspense
                                    fallback={<LoadingIcerik />}
                                >
                                    <motion.div
                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        whileHover={{ scale: 1.1 }}
                                    >
                                        <Img 
                                            src={icon_turkiye}
                                            alt='bayrak turkiye'
                                            style={{
                                                width: '30px',
                                            }}
                                            onClick={ () => setCookie('titizAgroWebLanguage', 'tr', {path: '/', maxAge: cookieMaxAge})}
                                        />
                                    </motion.div>
                                </Suspense>
                            </div>
                            <div
                                className='col-6 mt-2'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'left',
                                }}
                            >
                                <Suspense
                                    fallback={<LoadingIcerik />}
                                >
                                    <motion.div
                                        initial={{ opacity: 0, y: -50, scale: 1 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        whileHover={{ scale: 1.1 }}
                                    >
                                        <Img 
                                            src={icon_englandusd}
                                            alt='bayrak england'
                                            style={{
                                                width: '30px',
                                            }}
                                            onClick={ () => setCookie('titizAgroWebLanguage', 'eng', {path: '/', maxAge: cookieMaxAge})}
                                        />
                                    </motion.div>
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='col-12'
                >
                    <hr />
                </div>
            </div>
        </div>

        <AnimatePresence>
        {modalMobileMenu &&
        <>
            <motion.div
                initial="closed"
                animate="open"
                exit="closed"
                variants={modalMobileMenuAcKapat}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0,0,0, 0.8)',
                    zIndex: 30,
                }}
            >
                <div
                    className='container-fluid'
                    style={{
                        marginTop: '150px',
                        color: 'white',
                        fontSize: '30px',
                        fontWeight: 'bold'
                    }}
                >
                    <div
                        className='row'
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={ () => navigate2AnaSayfa() }
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >
                            {txtAnaSayfa}
                        </div>
                    </div>
                    <div
                        className='row m-3'
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >

                        </div>
                    </div>
                    <div
                        className='row'
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={ () => navigate2Hakkimizda() }
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >
                            {txtHakkimizda}
                        </div>
                    </div>
                    <div
                        className='row m-3'
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >

                        </div>
                    </div>
                    <div
                        className='row'
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={ () => navigate2Duyurular() }
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >
                            {txtDuyurular}
                        </div>
                    </div>
                    <div
                        className='row m-3'
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >

                        </div>
                    </div>
                    <div
                        className='row'
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={ () => navigate2Sirketlerimiz() }
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >
                            {txtSirketlerimiz}
                        </div>
                    </div>
                    <div
                        className='row m-3'
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >

                        </div>
                    </div>
                    <div
                        className='row'
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={ () => navigate2Iletisim() }
                    >
                        <div
                            className='col-12 yatayDikeyOrtala'
                        >
                            {txtIletisim}
                        </div>
                    </div>
                </div>    
            </motion.div>

            <motion.div
                initial="closed"
                animate="open"
                exit="closed"
                variants={modalMobileMenuAcKapat}

                style={{
                    position: 'fixed',
                    top: '150px',
                    right: '20px',
                    zIndex: 50
                }}
            >
                <Suspense
                    fallback={<LoadingIcerik />}
                >
                    <div>
                        <Img 
                            src={icon_englandusd}
                            alt='bayrak england'
                            style={{
                                width: '30px',
                            }}
                            onClick={ () => setCookie('titizAgroWebLanguage', 'eng', {path: '/', maxAge: cookieMaxAge})}
                        />
                    </div>
                </Suspense>
            </motion.div>
            <motion.div
                initial="closed"
                animate="open"
                exit="closed"
                variants={modalMobileMenuAcKapat}

                style={{
                    position: 'fixed',
                    top: '200px',
                    right: '20px',
                    zIndex: 50
                }}
            >
                <Suspense
                    fallback={<LoadingIcerik />}
                >
                    <div>
                        <Img 
                            src={icon_turkiye}
                            alt='bayrak turkiye'
                            style={{
                                width: '30px',
                            }}
                            onClick={ () => setCookie('titizAgroWebLanguage', 'tr', {path: '/', maxAge: cookieMaxAge})}
                        />
                    </div>
                </Suspense>
            </motion.div>

        </>
        }
        </AnimatePresence>

        {/* BOLUMLER */}
        {/* bolum 1 */}
        <div
            ref={refBolum1}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: 0,

                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 1</span>
        </div>
        {/* bolum 1 */}
        {/* bolum 2 */}
        {/* <div
            ref={refBolum2}
            style={{
                opacity: gsapBolumOpacity,
                position:'absolute',
                left: 0,
                top: '100vh',

                backgroundColor: 'rgba(155, 0, 0, 0.5)',
                width: '50px',
                height: '100vh',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span>bolum 2</span>
        </div> */}
        {/* bolum 2 */}
        {/* BOLUMLER */}

        </>
    )
} export default Baslik;