import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap"

import { useCookies } from 'react-cookie';

import { motion } from "framer-motion";


function Duyuru1() {

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const [txtDuyuruBaslik, setTxtDuyuruBaslik] = useState(null);
    const [txtDuyuruIcerik1, setTxtDuyuruIcerik1] = useState(null);
    const [txtDetay, setTxtDetay] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtDuyuruBaslik('Announcement Title');
            setTxtDuyuruIcerik1('We don`t have an announcement yet.');
            setTxtDetay('Detail');

        } else {
            setTxtDuyuruBaslik('Duyuru Başlık');
            setTxtDuyuruIcerik1('Henüz bir duyurumuz bulunmuyor.');
            setTxtDetay('Detay');

        }
        
    },[cookies.titizAgroWebLanguage])


    return(
        <>
        <motion.div
            initial={{ opacity: 0, y: -10, scale: 1 }}
            whileInView={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.05 }}
            className="m-3"
        >
            <Card>
                <Card.Body>
                    <div
                        className="container-fluid"
                    >
                        <div
                            className="row"
                        >
                            <div
                                className="col-12 yatayDikeyOrtala"
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <span>
                                    {txtDuyuruBaslik}
                                </span>
                            </div>
                            <div
                                className="col-12"
                            >
                                <hr />
                            </div>
                            <div
                                className="col-12"
                            >
                                <div
                                    className="container-fluid"
                                >
                                    <div
                                        className="row"
                                    >
                                        <div
                                            className="col-md-12 col-lg-4 yatayDikeyOrtala"
                                        >
                                            <div>
                                                <img 
                                                    src="https://placehold.co/600x400"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-12 col-lg-8 yatayDikeyOrtala"
                                        >
                                            <div
                                                className="container-fluid"
                                            >
                                                <div
                                                    className="row"
                                                >
                                                    <div
                                                        className="col-12"
                                                    >
                                                        <p>
                                                             {txtDuyuruIcerik1}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="col-12 yatayDikeyOrtala"
                                                    >
                                                        <Button
                                                            variant="outline-success"
                                                        >
                                                            {txtDetay}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </motion.div>
        </>
    )
} export default Duyuru1