
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import './Footer.css';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import iconFacebook from '../assets/icons/iconFacebook.png';
import iconInstagram from '../assets/icons/iconInstagram.png';
import iconTwitter from '../assets/icons/iconTwitter.png';
import iconYoutube from '../assets/icons/iconYoutube.png';

import titizAgroLogo_1 from '../assets/images/titizAgroLogo_1.png';
import titizLogo_1000x608 from '../assets/images/titizLogo_1000x608.webp';

import iconMapPointer from '../assets/icons/iconMapPointer.svg';
import iconTelefon from '../assets/icons/iconTelefon.svg';
import iconEmail from '../assets/icons/iconEmail.svg';
import LoadingIcerik from './LoadingIcerik';
import { Modal } from 'react-bootstrap';
import { Img } from 'react-suspense-img';

const LazyGoogleMapsComponent = lazy(() => import('./GoogleMapsComponent'));

function Footer() {

    const cookieMaxAge = 10 * 24 * 60 * 60;
    const [cookies, setCookie, removeCookie] = useCookies();

    const navigate = useNavigate();

    const navigate2AnaSayfa = () => {
        navigate('/');
    }
    const navigate2Hakkimizda = () => {
        navigate('/hakkimizda');
    }
    const navigate2Duyurular = () => {
        navigate('/duyuru');
    }
    const navigate2Sirketlerimiz = () => {
        navigate('/sirketlerimiz');
    }
    const navigate2Iletisim = () => {
        navigate('/iletisim');
    }

    const navigate2GenelKullanimKosullari = () => {
        navigate('/genelKullanimKosullari');
    }

    const navigate2GizlilikBildirimi = () => {
        navigate('/gizlilikBildirimi');
    }

    const [modalCerezBilgileri, setModalCerezBilgileri] = useState(false);
    const handleModalCerezBilgileriKapatFn = () => {
        setModalCerezBilgileri(false);
    }

    const [txtIletisim, setTxtIletisim] = useState(null);
    const [txtAnaSayfa, setTxtAnaSayfa] = useState(null);
    const [txtHakkimizda, setTxtHakkimizda] = useState(null);
    const [txtDuyurular, setTxtDuyurular] = useState(null);
    const [txtSirketlerimiz, setTxtSirketlerimiz] = useState(null);

    const [txtGenelKullanimKosullari, setTxtGenelKullanimKosullari] = useState(null);
    const [txtGizlilikBildirimi, setTxtGizlilikBildirimi] = useState(null);
    const [txtCerezBilgileri, setTxtCerezBilgileri] = useState(null);

    const [txtCerezBilgiText1, setTxtCerezBilgiText1] = useState(null);
    const [txtCerezBilgiText2, setTxtCerezBilgiText2] = useState(null);
    const [txtCerezBilgiText3, setTxtCerezBilgiText3] = useState(null);

    useEffect(() => {
        if(cookies.titizAgroWebLanguage === "eng"){
            setTxtIletisim('Contact');
            setTxtAnaSayfa('Home');
            setTxtHakkimizda('About Us');
            setTxtDuyurular('Announcements');
            setTxtSirketlerimiz('Group Companies');


            setTxtGenelKullanimKosullari('Genel Conditions of Use');
            setTxtGizlilikBildirimi('Privacy Statement');
            setTxtCerezBilgileri('Cookie Information');

            setTxtCerezBilgiText1('This Site uses cookies to improve the user experience and to ensure the efficient operation of the website. When you visit any website, it may store or receive information in your browser, mostly in the form of cookies. This information may be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information usually does not directly identify you, but may provide you with a more personalized web experience.');
            setTxtCerezBilgiText2('Changing or blocking cookie settings may affect your site experience and the services we can offer.');
            setTxtCerezBilgiText3('Please do not use this website unless you agree to these terms.');
        } else {
            setTxtIletisim('İletişim');
            setTxtAnaSayfa('Ana Sayfa')
            setTxtHakkimizda('Hakkımızda');
            setTxtDuyurular('Duyurular');
            setTxtSirketlerimiz('Şirketlerimiz');

            setTxtGenelKullanimKosullari('Genel Kullanım Koşulları');
            setTxtGizlilikBildirimi('Gizlilik Bildirimi');
            setTxtCerezBilgileri('Çerez Bilgileri');

            setTxtCerezBilgiText1('Bu Sitede, kullanıcı deneyimini geliştirmek ve internet sitesinin verimli çalışmasını sağlamak amacıyla çerezler kullanılmaktadır. Herhangi bir web sitesini ziyaret ettiğinizde, tarayıcınızda çoğunlukla tanımlama bilgileri biçiminde bilgi depolayabilir veya alabilir. Bu bilgiler sizinle, tercihlerinizle veya cihazınızla ilgili olabilir ve çoğunlukla sitenin beklediğiniz gibi çalışmasını sağlamak için kullanılır. Bilgiler genellikle sizi doğrudan tanımlamaz, ancak size daha kişiselleştirilmiş bir web deneyimi sağlayabilir.');
            setTxtCerezBilgiText2('Çerez ayarlarının değiştirilmesi veya engellenmesi site deneyiminizi ve sunabileceğimiz hizmetleri etkileyebilir.');
            setTxtCerezBilgiText3('Lütfen bu koşulları kabul etmediğiniz sürece bu İnternet sitesini kullanmayın.');
        }
        
    },[cookies.titizAgroWebLanguage])


    const refDiv1 = useRef(null);

    const handleClickFacebook = () => {
        window.open('https://www.facebook.com/Tiztizagrogrup', '_blank');
    };

    const handleClickTwitter = () => {
        window.open('https://twitter.com/titizagrogrup', '_blank');
    };

    const handleClickInstagram = () => {
        window.open('https://www.instagram.com/titizagrogrup/', '_blank');
    };

    const handleClickYoutube = () => {
        window.open('https://www.youtube.com/@titizagrogrup', '_blank');
    };

    const handleTelefonLink1 = () => {
        window.location.href = 'tel:00902423212330';
    }
    const handleTelefonLink2 = () => {
        window.location.href = 'tel:00902423214687';
    }
    const handleEmailLink1 = () => {
        window.location.href = 'mailto:info@antalyatarim.com.tr';
    }
    const googleMapsLink = `
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12854.036820804666!2d30.700221016075865!3d36.882886177301124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385396551db85%3A0x45b45cbf12cedc8e!2sTitiz%20Agro%20Group!5e0!3m2!1str!2str!4v1706083277430!5m2!1str!2str" 
            width="100%"
            height="200" 
            style="border:0;" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
        </iframe>
    `;

    return(
        <>
        <div
            className='fontGenel'
        >
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}

                className='container-fluid'
                style={{
                    width: '100vw',
                    // height: '270px',
                    backgroundColor: 'rgba(255, 158, 59, 0.5)',
                    paddingBottom: '100px',
                }}
            >
                <div
                    className='row'
                >
                    <div
                        className='col-12 yatayDikeyOrtala'
                    >
                        <div
                            className='container-fluid mt-3'
                        >
                            <div
                                className='row'
                            >
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                    style={{
                                        // backgroundColor: 'yellow',
                                    }}
                                >
                                    <div
                                        className='container-fluid'
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div
                                            className='row'
                                        >
                                            <div
                                                className='col-12 yatayDikeyOrtala'
                                            >
                                                <div
                                                    style={{
                                                        padding: '25px',
                                                        borderRadius: '25px',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                                    }}
                                                >
                                                    <Suspense
                                                        fallback={<LoadingIcerik />}
                                                    >
                                                        <Img 
                                                            src={titizLogo_1000x608}
                                                            alt='titiz logo'
                                                            style={{
                                                                maxWidth: '150px',
                                                            }}
                                                        />
                                                    </Suspense>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                > 
                                    <div
                                        className='container-fluid'
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div
                                            className='row'
                                        >
                                            <div
                                                className='col-12 yatayDikeyOrtala'
                                            >
                                                <ul>
                                                    <li
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={ () => navigate2AnaSayfa() }
                                                    >
                                                        {txtAnaSayfa}
                                                    </li>
                                                    <li
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={ () => navigate2Hakkimizda() }
                                                    >
                                                        {txtHakkimizda}
                                                    </li>
                                                    <li
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={ () => navigate2Duyurular() }
                                                    >
                                                        {txtDuyurular}
                                                    </li>
                                                    <li
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={ () => navigate2Sirketlerimiz() }
                                                    >
                                                        {txtSirketlerimiz}
                                                    </li>
                                                    <li
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={ () => navigate2Iletisim() }
                                                    >
                                                        {txtIletisim}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                >
                                    <div
                                        className='container-fluid'
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div
                                            className='row mt-1'
                                        >
                                            <div
                                                className='col-12 yatayDikeyOrtala'
                                            >
                                                <span>
                                                    Merkez (Antalya) Bölge
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className='row'
                                        >
                                            <div
                                                className='col-2 yatayDikeyOrtala'
                                            >
                                                <Suspense
                                                    fallback={<LoadingIcerik />}
                                                >
                                                    <Img 
                                                        src={iconMapPointer}
                                                        alt='icon mapPointer'
                                                        style={{
                                                            width: '20px',
                                                        }}
                                                    />
                                                </Suspense>
                                            </div>
                                            <div
                                                className='col-10 yatayDikeyOrtala'
                                            >
                                                <span
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Kızıltoprak Mahallesi Aspendos Bulvarı No: 37 Muratpaşa/Antalya
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className='row mt-2'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ handleTelefonLink1 }
                                        >
                                            <div
                                                className='col-2 yatayDikeyOrtala'
                                            >
                                                <Suspense
                                                    fallback={<LoadingIcerik />}
                                                >
                                                    <Img 
                                                        src={iconTelefon}
                                                        alt='icon telefon'
                                                        style={{
                                                            width: '20px',
                                                        }}
                                                    />
                                                </Suspense>
                                                <img 
                                                />
                                            </div>
                                            <div
                                                className='col-10'
                                            >
                                                <span
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    +90 (242) 321 23 30
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className='row mt-2'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ handleTelefonLink2 }
                                        >
                                            <div
                                                className='col-2 yatayDikeyOrtala'
                                            >
                                                <Suspense
                                                    fallback={<LoadingIcerik />}
                                                >
                                                    <Img 
                                                        src={iconTelefon}
                                                        alt='icon telefon'
                                                        style={{
                                                            width: '20px',
                                                        }}
                                                    />
                                                </Suspense>
                                            </div>
                                            <div
                                                className='col-10'
                                            >
                                                <span
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    +90 (242) 321 46 87
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className='row mt-2'
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={ handleEmailLink1 }
                                        >
                                            <div
                                                className='col-2 yatayDikeyOrtala'
                                            >
                                                <Suspense
                                                    fallback={<LoadingIcerik />}
                                                >
                                                    <Img 
                                                        src={iconEmail}
                                                        alt='icon email'
                                                        style={{
                                                            width: '20px',
                                                        }}
                                                    />
                                                </Suspense>
                                            </div>
                                            <div
                                                className='col-10'
                                            >
                                                <span
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    info@antalyatarim.com.tr
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                >
                                    <Suspense
                                        fallback={<LoadingIcerik />}
                                    >
                                        <LazyGoogleMapsComponent googleMapsLink={googleMapsLink} />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div
                    className='row'
                >
                    <div
                        className='col-12 yatayDikeyOrtala'
                    >

                        <div
                            className='container-fluid mt-5'
                            style={{
                                // maxWidth: '900px',
                                maxWidth: '50vw',
                                fontSize: '12px',
                            }}
                        >
                            <div
                                className='row yatayDikeyOrtala'
                            >
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={ () => navigate2GenelKullanimKosullari() }
                                >
                                    <span
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        {txtGenelKullanimKosullari}
                                    </span>
                                </div>
                                <div
                                    className='col-md-12 col-lg-1 yatayDikeyOrtala'
                                >
                                    <span> / </span>
                                </div>
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={ () => navigate2GizlilikBildirimi() }
                                >
                                    <span
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        {txtGizlilikBildirimi}
                                    </span>
                                </div>
                                <div
                                    className='col-md-12 col-lg-1 yatayDikeyOrtala'
                                >
                                    <span> / </span>
                                </div>
                                <div
                                    className='col-md-12 col-lg-3 yatayDikeyOrtala'
                                    onClick={ () => setModalCerezBilgileri(true) }
                                >
                                    <span
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        {txtCerezBilgileri}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
            </motion.div>


<Modal
    show={modalCerezBilgileri}
    onHide={handleModalCerezBilgileriKapatFn}
    size='lg'
>
    <Modal.Header
        closeButton
    >
        <div
            className='container-fluid'
        >
            <div
                className='row'
            >
                <div
                    className='col-12'
                    style={{
                        fontWeight: 'bold',
                    }}
                >
                    <span>{txtCerezBilgileri}</span>
                </div>
            </div>
        </div>
    </Modal.Header>
    <Modal.Body>
        <div
            className='container-fluid'
        >
            <div
                className='row'            
            >
                <div
                    className='col-12'
                >
                    {txtCerezBilgiText1}
                </div>
                <div
                    className='col-12 m-3 yatayDikeyOrtala'
                >

                </div>
                <div
                    className='col-12'
                >
                    {txtCerezBilgiText2}
                </div>
                <div
                    className='col-12 m-3 yatayDikeyOrtala'
                >

                </div>
                <div
                    className='col-12'
                >
                    {txtCerezBilgiText3}
                </div>
            </div>
        </div>
    </Modal.Body>
</Modal>


{/* .SM. */}
    <motion.div
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ delay: 1 }}
        
        className='d-none d-lg-block'
        style={{
            position: 'absolute',
            bottom: '290px',
            right: '10px',
            zIndex: 100
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
        }}
    >
        <ul className="wrapperIletisimSosyalMedya test"
            ref={refDiv1}
        >
            <li 
                className="iconIletisimSosyalMedya facebook"
                onClick={ () => handleClickFacebook() }
            >
                <span className="App tooltip">Facebook</span>
                <span>
                    <Suspense
                        fallback={<LoadingIcerik />}
                    >
                        <Img
                            src={iconFacebook}
                            alt='icon facebook'
                            style={{
                                width: '25px',
                            }}
                        />
                    </Suspense>
                </span>
            </li>
            <li 
                className="iconIletisimSosyalMedya twitter"
                onClick={ () => handleClickTwitter() }
            >
                <span className="App tooltip"
                    style={{
                        whiteSpace: 'nowrap'
                    }}
                >X (Twitter)</span>
                <span>
                    <Suspense
                        fallback={<LoadingIcerik />}
                    >
                        <Img 
                            src={iconTwitter} 
                            alt='icon twitter'
                            style={{
                                width: '22px',
                            }}
                        />
                    </Suspense>
                </span>
            </li>
            <li 
                className="iconIletisimSosyalMedya instagram"
                onClick={ () => handleClickInstagram() }
            >
                <span className="App tooltip">Instagram</span>
                <span>
                    <Suspense
                        fallback={<LoadingIcerik />}
                    >
                        <Img
                            src={iconInstagram} 
                            alt='icon instagram'
                            style={{
                                width: '25px',
                            }}
                        />
                    </Suspense>
                </span>
            </li>
            <li 
                className="iconIletisimSosyalMedya youtube"
                onClick={ () =>handleClickYoutube() }
            >
                <span className="App tooltip">Youtube</span>
                <span>
                    <Suspense
                        fallback={<LoadingIcerik />}
                    >
                        <Img 
                            src={iconYoutube} 
                            alt='icon youtube'
                            style={{
                                width: '25px',
                            }}
                        />
                    </Suspense>
                </span>
            </li>
        </ul>
    </motion.div>
{/* .SM. */}


        </div>
        </>
    )
} export default Footer