import { lazy, Suspense, useEffect, useRef, useState } from "react";
// import Baslik from "../comps/Baslik";
// import Tanitim1 from "../comps/Tanitim1";
// import DalgaAlt from "../comps/DalgaAlt";

import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import Slider1 from "../comps/Slider1";
// import Footer from "../comps/Footer";
// import UrunGruplari from "../comps/UrunGruplari";
import Loading from "../comps/Loading";

const Baslik = lazy(() => import('../comps/Baslik'));
const Tanitim1 = lazy(() => import('../comps/Tanitim1'));
const Slider1 = lazy(() => import('../comps/Slider1'));
const Slider2 = lazy(() => import('../comps/Slider2'));
const Footer = lazy(() => import('../comps/Footer'));
const DalgaAlt = lazy(() => import('../comps/DalgaAlt'));




function AnaSayfa() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 0);
    },[])

    const refIcerik1 = useRef();
    const refBolum1 = useRef();
    const refBolum2 = useRef();

    const [gsapBolumOpacity, setGsapBolumOpacity] = useState(0);

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger);

        const icerik1 = refIcerik1.current;
        
        const bolum1 = refBolum1.current;
        const bolum2 = refBolum2.current;

        gsap.fromTo(icerik1,
            {
                opacity: 1,
                x: 0,
            },
            {
                opacity: 0,
                x: -50,
                scrollTrigger: {
                    trigger: bolum1,
                    start: '60% center',
                    end: 'bottom center',
                    scrub: true,
                    // markers: true,
                }
            }
        )

    },[])

    return(
        <>
        <Suspense 
            fallback={<Loading />}
        >

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    maxWidth: '100vw',
                    minHeight: '100vh',
                    overflowX: "hidden",
                    overflowY: "hidden",
                    backgroundColor: 'white',
                }}
            >
            <Baslik />
            <div
                style={{
                    width: '100vw',
                    height: '105px',
                }}
            >
            </div>
            <div>
                <Slider1 />
                {/* <Slider2 /> */}
                {/* <Tanitim1 /> */}
                {/* <UrunGruplari /> */}
            </div>
            <div
                style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                }}
            >
                <Footer />
            </div>
            <DalgaAlt />

            {/* BOLUMLER */}
            {/* bolum 1 */}
            <div
                ref={refBolum1}
                style={{
                    opacity: gsapBolumOpacity,
                    position:'absolute',
                    left: 0,
                    top: 0,

                    backgroundColor: 'rgba(255, 0, 0, 0.5)',
                    width: '50px',
                    height: '100vh',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span>bolum 1</span>
            </div>
            {/* bolum 1 */}
            {/* bolum 2 */}
            {/* <div
                ref={refBolum2}
                style={{
                    opacity: gsapBolumOpacity,
                    position:'absolute',
                    left: 0,
                    top: '100vh',

                    backgroundColor: 'rgba(155, 0, 0, 0.5)',
                    width: '50px',
                    height: '100vh',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span>bolum 2</span>
            </div> */}
            {/* bolum 2 */}
            {/* BOLUMLER */}
            </div>
            
        </Suspense>
        </>
    )
} export default AnaSayfa;