

import './DalgaAlt.css';

import { motion } from "framer-motion";

function DalgaAlt() {

  const navigate2titiz = () => {
    // window.open("https://www.titizagrogrup.com.tr", "_blank");
    window.open("#", "_self");
  }

  const currentYear = new Date().getFullYear();

    return(
        <>
          <div
              style={{ position: 'fixed', 
                      bottom: '0', left: '0',
                      width: '100vw',
                      zIndex: 100
                  }}    
          >
            <div>
              <svg className="wavesAlt" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                  <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                  <use xlinkHref="#gentle-wave" x={48} y={0} fill="rgba(255,255,255,0.7" />
                  <use xlinkHref="#gentle-wave" x={48} y={3} fill="rgba(255,255,255,0.5)" />
                  <use xlinkHref="#gentle-wave" x={48} y={5} fill="rgba(255,255,255,0.3)" />
                  <use xlinkHref="#gentle-wave" x={48} y={7} fill="#fff" />
                </g>
              </svg>
            </div>
          </div>
          <div
              className='App '
              style={{ 
                  width: 'calc(100vw)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
              }}
          >
              <span className='altYazi1'
                style={{
                  zIndex: 101
                }}
              >
                Titiz Agro Grup A.Ş. - {currentYear} &copy;
              </span>
          </div>
        </>
    )
} export default DalgaAlt